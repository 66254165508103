import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Address } from '../models/address';
import { User } from '../models/user';
import { CrudService } from './crud.service';
import { Global } from './global';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends CrudService<User> {

  constructor(protected http: HttpClient, private auth: AuthService) {
    super(http);
    this.endpoint = { base: `${environment.baseUrl}/users` };
  }

  login(username: string, password: string) {
    return this.http
      .post(`${environment.baseUrl}/auth/local`, {
        identifier: username,
        password,
      })
      .toPromise()
      .then((res: { jwt: string; user: any }) => {
        this.auth.setIdentity(res.user);
        this.auth.setToken(res.jwt);
      });
  }

  me() {
    return this.http
      .get<any>(`${environment.baseUrl}/users/me`)
      .toPromise()
      .then((res) => {
        this.auth.setIdentity(res);
        return res;
      });
  }

  register(user: { name: string, surname: string, cellphone: number, email: any, password: any, username: string, address: Address }) {
    user.username = user.email;
    return this.http
      .post(`${environment.baseUrl}/auth/local/register`, user)
      .toPromise()
      .then((res: { jwt: string, user: any}) => {
        this.auth.setIdentity(res.user);
        this.auth.setToken(res.jwt);
      })
  }

  getStatistics() {
    return this.http
      .get<any>(`${environment.baseUrl}/users/stats`)
      .toPromise();
  }
}
