import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HgHeaderComponent } from './hg-header/hg-header.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import rrulePlugin from '@fullcalendar/rrule'; // a plugin!
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { UploadImgComponent } from './upload-img/upload-img.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { EstateCalendarComponent } from './estate-calendar/estate-calendar.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { ModalComponent } from './modal/modal.component';
import { RouterModule } from '@angular/router';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery'
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { FooterComponent } from './footer/footer.component';
import { SendRequestComponent } from './send-request/send-request.component';
import { VisitorsCounterComponent } from './visitors-counter/visitors-counter.component';
import { ConfirmReservationComponent } from './confirm-reservation/confirm-reservation.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PaymentModalComponent } from 'src/app/components/payment-modal/payment-modal.component';
import { NgxStripeModule } from 'ngx-stripe';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  rrulePlugin
]);

const components = [
  FooterComponent, PhotogalleryComponent, HgHeaderComponent, 
  UploadImgComponent, ServiceFormComponent, EstateCalendarComponent, 
  HeaderMenuComponent, ModalComponent, SendRequestComponent,
  VisitorsCounterComponent, ConfirmReservationComponent,
  MaintenanceComponent, PaymentModalComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    FullCalendarModule,
    RouterModule,
    FlatpickrModule,
    PhotoGalleryModule,
    NgxStripeModule
  ],
  exports: components
})
export class ComponentsModule { }
