import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Calendar, CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ServiceConfiguration } from 'src/app/models/serviceConfiguration';
import { ServiceConfigurationService } from 'src/app/services/service-configuration.service';

@Component({
  selector: 'estate-calendar',
  templateUrl: './estate-calendar.component.html',
  styleUrls: ['./estate-calendar.component.scss'],
})
export class EstateCalendarComponent implements OnInit {

  @ViewChild('calendar') calendar: FullCalendarComponent;
  @Input('config') config: ServiceConfiguration;
  @Input('title') title: string;

  @Output('back') back: EventEmitter<any> = new EventEmitter<any>();
  @Output('save') save: EventEmitter<ServiceConfiguration> = new EventEmitter<ServiceConfiguration>();

  public step: 'step1' | 'step2' | 'choose_turns' = 'step1';
  public today: Date = new Date();
  public date: Date;
  public day: Date = new Date(2021, 0, 1);
  public showPreparation: boolean;
  public calendarOptions: CalendarOptions = {
    firstDay: 1,
    locale: this.translate.getBrowserLang(),
    dateClick: this.calendarDateClick.bind(this),
    datesSet: ((dates) => {
      console.log("A", dates);
      this.currentDates = dates;
      this.updateEvents(dates);
    }).bind(this),
    events: []
    // initialView: 'dayGridMonth'
  };
  public calendarReady: boolean = false;
  public week = {
    'lun': false,
    'mar': false,
    'mer': false,
    'gio': false,
    'ven': false,
    'sab': false,
    'dom': false,
  };

  public indexes = {
    'lun': 1,
    'mar': 2,
    'mer': 3,
    'gio': 4,
    'ven': 5,
    'sab': 6,
    'dom': 0,
  };
  public weekLabels = ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom'];
  public turns = {};
  private calendarAPI: Calendar;
  private currentDates;
  public timeOptions = {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true,
    time24hr: true
  };

  constructor(private translate: TranslateService, private serviceConfigService: ServiceConfigurationService) { }

  async ngOnInit() {
    if (this.config.id) {
      this.config = await this.serviceConfigService.findOne(this.config.id);
    }
    setTimeout(() => {
      this.config = this.config || new ServiceConfiguration();
      if (this.config.week?.length) {
        this.config.week.map(k => {
          this.week[k] = true;
        });
        this.updateWeek();
      }
      this.calendar.getApi().updateSize();
      this.calendarAPI = this.calendar.getApi();
    }, 500);

    this.config.turn_preparation = parseInt(`${this.config.turn_preparation}`) || undefined;
    this.config.turn_period = parseInt(`${this.config.turn_period}`) || undefined;
    this.showPreparation = this.config.turn_preparation > 0;

  }

  setDateInput(config, key, value) {
    const date = new Date(config[key]);
    date.setHours(value.getHours(), value.getMinutes());
    config[key] = date;
    this.updateEvents();
  }

  getTime(date: Date) {
    return moment(date).format('HH:mm');
  }

  calendarDateClick($event) {
    if (this.config.type) return;
    let time = 'T00:00';
    if (this.config.startDate) {
      const date = new Date(this.config.startDate);
      time = `T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }
    this.config.startDate = $event.dateStr + time as any;

    let endTime = 'T23:59';
    if (this.config.endDate) {
      const date = new Date(this.config.endDate);
      endTime = `T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }
    this.config.endDate = $event.dateStr + endTime as any;
    this.calendarOptions.events = [{
      title: this.title,
      start: this.config.startDate,
      end: this.config.endDate,
      backgroundColor: 'red'
    }];
  }

  updateWeek() {
    this.config.week = Object.keys(this.week).filter(w => this.week[w]);
    this.updateEvents(this.currentDates);
  }

  updateEvents(dates = null) {
    if (!this.config.startDate) return;
    dates = dates || this.currentDates;
    let events = [];
    const startDate = new Date(this.config.startDate);
    const clearStartDate = new Date(startDate.getTime());
    clearStartDate.setHours(0, 0, 0, 0);
    let timeEnd = dates.end.getTime() - dates.start.getTime();
    let currentDate = new Date(dates.start.getTime());
    currentDate.setHours(startDate.getHours(), startDate.getMinutes(), 0);

    if (this.config.type == 'monthly') {
      const grade = this.getGradeOfWeekDay();
      const daysCurrentMonth = this.getWeekDaysInMonth(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), clearStartDate.getDay());
      const daysNextMonth = this.getWeekDaysInMonth(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1), clearStartDate.getDay());
      const daysNextNextMonth = this.getWeekDaysInMonth(new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 1), clearStartDate.getDay());
      events = [
        {
          title: this.title,
          start: daysCurrentMonth[grade - 1],
          backgroundColor: 'red'
        },
        {
          title: this.title,
          start: daysNextMonth[grade - 1],
          backgroundColor: 'red'
        },
        {
          title: this.title,
          start: daysNextNextMonth[grade - 1],
          backgroundColor: 'red'
        }
      ];
    } else if (this.config.type == 'weekly') {
      const dayOfWeek = Object.keys(this.week).map((k) => this.week[k] ? this.indexes[k] : null).filter(i => i !== null);
      while (timeEnd > 0) {
        if (currentDate.getTime() >= clearStartDate.getTime() && dayOfWeek.indexOf(currentDate.getDay()) > -1) {
          events.push({
            title: this.title,
            start: currentDate,
            backgroundColor: 'red'
          });
        }
        currentDate = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000));
        timeEnd -= 24 * 60 * 60 * 1000;
      }
    } else {
      events = [{
        title: this.title,
        start: this.config.startDate,
        end: this.config.endDate,
        backgroundColor: 'red'
      }];
    }
    this.calendarOptions.events = events;
  }

  setDate($event) {
    console.log($event);
    this.config.maxBookingDate = 'specific';
  }

  canShowInfo() {
    return true;
  }

  togglePreparation() {
    if (!this.showPreparation) {
      this.config.turn_preparation = undefined;
      return;
    }
  }

  generateTurns() {
    const turns = [];

    let date = new Date(this.config.startDate);
    let endTime = new Date(this.config.endDate);
    date = new Date(2020, 1, 1, date?.getHours() || 0, date?.getMinutes() || 0, 0, 0);
    endTime = new Date(2020, 1, 1, endTime?.getHours() || 0, endTime?.getMinutes() || 0, 0, 0);
    let minutesInADay = (endTime.getTime() - date.getTime()) / 60000;

    while (minutesInADay > 0) {
      const start = moment(date).format('HH:mm');
      date = new Date(date.getTime() + ((this.config.turn_period || 0) * 60 * 1000));
      const end = moment(date).format('HH:mm');
      const turn = `${start} - ${end}`;
      minutesInADay = minutesInADay - (this.config.turn_period || 0) - (this.config.turn_preparation || 0);
      date = new Date(date.getTime() + ((this.config.turn_preparation || 0) * 60 * 1000));
      turns.push(turn);
    }

    return turns;
  }

  toggleTurns(label: string) {
    this.config.turns = this.config.turns || [];
    if (this.config.turns.find(t => t.label == label)) {
      this.config.turns = this.config.turns.filter(t => t.label != label);
      return;
    }
    const turn = { label, start: new Date(label.split(' - ')[0]), end: new Date(label.split(' - ')[1]) };
    this.config.turns.push(turn);
  }

  isTurnSelected(label: string) {
    return !!this.config?.turns?.find(t => t.label == label);
  }

  goBack() {
    if (confirm("Tornando alla lista servizi perderai le modifiche sul programma. Sei sicuro di voler tornare indietro?")) {
      this.back.emit();
    }
  }

  getGradeOfWeekDay() {

    if (!this.config?.startDate) {
      return null;
    }

    var day, counter = 0, date;

    day = 1;
    const dateStart = new Date(this.config.startDate);
    const year = dateStart.getFullYear();
    const month = dateStart.getMonth();
    const d = dateStart.getDate();
    date = new Date(year, month, day);
    while (date.getMonth() === month && d > day) {
      if (date.getDay() === dateStart.getDay()) { // Sun=0, Mon=1, Tue=2, etc.
        counter += 1;
      }
      day += 1;
      date = new Date(year, month, day);
    }
    return counter + 1;
  }

  getWeekDaysInMonth(date: Date, dayOfWeek: number) {
    const d = new Date(date);
    let month = date.getMonth();
    let dayOfWeeks = [];

    d.setDate(1);

    // Get the first Monday in the month
    while (d.getDay() !== dayOfWeek) {
      d.setDate(d.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (d.getMonth() === month) {
      dayOfWeeks.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
    }

    return dayOfWeeks;
  }

  saveFn() {
    this.save.emit(this.config);
  }

}
