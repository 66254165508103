import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as qs from "qs";

@Injectable({
  providedIn: 'root',
})
export class CrudService<T extends ({ id: string })> {
  protected endpoint: Enpoints = new Enpoints();
  protected defaultQueryStrings: DefaultQueryString = new DefaultQueryString();

  constructor(protected http: HttpClient) { }

  find(where: any, search: string, skip: number = 0, take: number = 20, orderBy: string = null): Promise<T[]> {
    let query = {
      _where: where || this.defaultQueryStrings._where || {},
      _start: skip || this.defaultQueryStrings._start || 0,
      _limit: take || this.defaultQueryStrings._limit || 200,
      _sort: orderBy || this.defaultQueryStrings._sort,
      _q: search
    }
    return this.http.get<T[]>(`${this.endpoint.find || this.endpoint.base}?${qs.stringify(query)}`).toPromise();
  }

  findOne(id: any, where: any = {}, params: { [key: string]: string } = null): Promise<T> {
    let query = { _where: where || this.defaultQueryStrings._where || {} };
    let _params = '';
    if (params) {
      for (let key in params) {
        _params += `&${key}=${encodeURIComponent(params[key])}`;
      }
    }
    return this.http.get<T>(`${this.endpoint.findOne || this.endpoint.base}/${id}?${qs.stringify(query)}&${_params}`).toPromise();
  }

  add(entity: T): Promise<T> {
    return this.http.post<T>(`${this.endpoint.add || this.endpoint.base}`, entity).toPromise();
  }

  update(entity: Partial<T>): Promise<T> {
    return this.http.put<T>(`${this.endpoint.update || this.endpoint.base}/${entity.id}`, entity).toPromise();
  }

  delete(id: any): Promise<T> {
    return this.http.delete<T>(`${this.endpoint.delete || this.endpoint.base}/${id}`).toPromise();
  }

  count(filter: any = {}, search: string = ''): Promise<any> {
    let _where = [];
    for (let key in filter) {
      if (filter[key]) {
        let element = {};
        element[key] = filter[key];
        _where.push(element)
      }
    }
    const query = qs.stringify({ _where });
    return this.http.get<any>(`${this.endpoint.findOne || this.endpoint.base}/count?_q=${search || ''}&${query}`).toPromise();
  }
}

export class Enpoints {
  base: string;
  find?: string;
  findOne?: string;
  add?: string;
  update?: string;
  delete?: string;
  [key: string]: string;
}

export class DefaultQueryString {
  _where: any;
  _start: any;
  _limit: any;
  _sort: string;
  _fields: any;
}
