import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import flatpickr from "flatpickr";
import IT from "flatpickr/dist/l10n/it";
import { Global } from './services/global';
import { SettingsService } from './services/settings.service';
import { showCookiePolicy } from 'src/app/utils/cookie-policy';
flatpickr.localize(IT.it);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isMaintenance: boolean = Global.settings.isMaintenance;

  constructor(private translate: TranslateService, private settingsService: SettingsService) {

    this.settingsService.find({}, undefined).then((settings) => {
      Global.settings = settings as any;
      this.isMaintenance = Global.settings.isMaintenance;
    });

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.translate.getBrowserLang());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(this.translate.getBrowserLang());

    this.showCookiePolicy();

  }

  async showCookiePolicy() {
    await showCookiePolicy(
      await this.translate.get('cookiePolicy').toPromise(), 
      await this.translate.get('cookieMessage').toPromise(),
      await this.translate.get('acceptButton').toPromise(),
      await this.translate.get('linkLabel').toPromise(),
      );
  }
}
