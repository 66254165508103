import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { StoreService } from '../../services/store.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { showCookiePolicy } from 'src/app/utils/cookie-policy';

const LANGUAGE_KEY = 'DEFAULT_LANGUAGE';

@Component({
  selector: 'hg-header',
  templateUrl: './hg-header.component.html',
  styleUrls: ['./hg-header.component.scss'],
})
export class HgHeaderComponent implements OnInit {

  @Input('options') options: any = {};
  @Input('lang') lang = 'it';
  public user: any;
  public user$: Observable<any>;

  constructor(private translate: TranslateService,
    public navCtrl: NavController,
    public auth: AuthService,
    private usersService: UsersService,
    public popoverCtrl: PopoverController,
    private store: StoreService,
    private router: Router
  ) {
    this.lang = store.getLocalStorage(LANGUAGE_KEY) || this.translate.getBrowserLang();
    this.translate.use(this.lang);
    this.user$ = auth.user$.asObservable();
  }


  async ngOnInit() {
    this.user = await this.usersService.me();
  }

  async changeLang($event) {
    console.log($event);
    this.translate.use($event.detail.value);
    this.store.storeLocalStorage($event.detail.value, LANGUAGE_KEY);
    showCookiePolicy(
      await this.translate.get('cookiePolicy').toPromise(),
      await this.translate.get('cookieMessage').toPromise(),
      await this.translate.get('acceptButton').toPromise(),
      await this.translate.get('linkLabel').toPromise(),
    );
  }

  loginClick($event) {
    if (this.auth.isAuthenticate()) {
      return;
    }
    this.navCtrl.navigateForward('/login');
  }

  gotoHome() {
    // if (this.auth.isAuthenticate()) {
    //   this.navCtrl.navigateRoot('/dashboard/estates');
    //   return;
    // }
    this.navCtrl.navigateRoot('/');
  }

  async presentMenu(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: HeaderMenuComponent,
      event: ev
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  goToDashboard() {
    this.navCtrl.navigateRoot('/dashboard/estate');
  }

  get owner() {
    return this.auth.hasRoles(['owner']);
  }

}
