import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Reservation } from '../models/reservation';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService extends CrudService<Reservation> {

  constructor(protected http: HttpClient) {
    super(http);
    this.endpoint = {
      base: `${environment.baseUrl}/reservations`
    };

    this.defaultQueryStrings._sort = 'created_at:desc';
  }

  checkout(service_id: any, date: Date, pax: number, turn: string, user_id: any, paymentToken: string) {
    return this.http.post<{ service_type: 'automatica' | 'manuale', reservation: Reservation; }>(`${this.endpoint.base}/checkout`, {
      service_id,
      date,
      pax,
      turn,
      user_id,
      paymentToken
    }).toPromise();
  }
  async getStatistics() {
    return await this.http.get<{ total: number, provinces: { [key: string]: number; }; }>(`${this.endpoint.base}/stats`).toPromise();
  }
}
