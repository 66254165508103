import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Global} from '../services/global';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public onLogout: EventEmitter<any> = new EventEmitter<any>();

  private identity: any;
  private token: string;
  private personation_token: string;

  constructor(private http: HttpClient) {
    this.identity = this.getPayload(this.getToken());


    this.personation_token = this.getToken();
    this.getIdentity().then(res => {
    });
  }

  isAuthenticate(): boolean {
    ///console.log(this.token || localStorage.getItem('token'))
    return !!(this.token || localStorage.getItem('token'));
  }

  private _pendingMe: boolean;

  getIdentity() {
    // if (!this.identity?.role) {
    //   if (!this._pendingMe) { this.identity = await this.me() };
    //   this._pendingMe = true;
    //   setTimeout(() => {
    //     this._pendingMe = false;
    //   }, 2000)
    // }

    return this.identity;
  }

  setIdentity(identity: any): void {
    this.identity = identity;
    this.user$.next(this.identity);
  }

  getToken() {
    return this.token || localStorage.getItem('token')
  }

  setToken(token: string): void {
    this.token = token;
    this.impersonate(token);
    if (!token) {
      localStorage.removeItem('token');
      return;
    }
    localStorage.setItem('token', token);
  }

  async getPayload(token: string): Promise<any> {
    if (!token) {
      this.user$.next(null);
      return {}
    };
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');

    let data: any = JSON.parse(window.atob(base64));
    return data;
  }

  hasRoles(roles: string[]): boolean {
    // const user_roles = this.getIdentity()?.role ? [this.getIdentity()?.role?.type] : [];
    // return !!user_roles.find(ur => roles.find(r => r == ur))
    // TODO: Check better mode
    if (roles[0] === 'owner' && this.getIdentity()?.owner) {
      return true;
    }
    if (roles[0] === 'tourist' && !this.getIdentity()?.owner) {
      return true;
    }

    if(roles.length > 0 && roles[0] !== 'owner' && roles[0] !== 'tourist') {
      return !!roles.find(r => r == this.getIdentity()?.role?.type)
    }

    return false
  }

  get owner() {
    return this.getIdentity()?.owner;
  }

  impersonate(token: string): void {
    this.personation_token = token;
  }

  getPersonation(): string {
    return this.personation_token || this.getToken();
  }

  logout() {
    this.setToken(null);
    this.setIdentity(null);
    this.user$.next(null);
    this.onLogout.emit(true);
  }

  me() {
    return this.http
      .get<any>(`${Global.ENDPOINTS.BASE}/users/me`)
      .toPromise();
  }

}
