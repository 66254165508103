import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Settings } from '../models/settings';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends CrudService<Settings> {

  constructor(protected http: HttpClient) {
    super(http);
    this.endpoint = {
      base: `${environment.baseUrl}/settings`
    }
  }

  async checkMaintenancePassword(password: string) {
    return await this.http.post(`${this.endpoint.base}/password`, { password })
      .toPromise()
      .then((res: any) => res.status == 'ok');
  }
}
