import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Global } from 'src/app/services/global';

@Component({
  selector: 'upload-img',
  templateUrl: './upload-img.component.html',
  styleUrls: ['./upload-img.component.scss'],
})
export class UploadImgComponent implements OnChanges {

  public base64: string;
  @Input('file') file: File;
  @Input('img') img: { url?: string, [key: string]: any };
  @Output('preview') preview: EventEmitter<{ file: File, base64: string }> = new EventEmitter();
  @Output('remove') remove: EventEmitter<any> = new EventEmitter();

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.file && this.file instanceof File) {
      this.getBase64(this.file);
    }
    if (this.img?.url) {
      this.base64 = Global.ENDPOINTS.BASE + this.img?.url;
    }
  }

  previewImage($event) {
    const [file] = $event.target.files;
    if (file) {
      this.base64 = URL.createObjectURL(file);
      this.preview.emit({ file, base64: this.base64 });
    }
  }

  getBase64(file: File) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64 = reader.result as string;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  deleteImg() {
    this.file = null;
    this.img = null;
    this.base64 = null;
    this.remove.emit();
  }
}
