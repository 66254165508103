import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { AuthService } from '../auth.service';
import {catchError, tap} from "rxjs/operators";
import {NavController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private navCtrl: NavController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem("token");
        const headers = {};
        const coords = localStorage.getItem("coords")
        if (token) headers['Authorization'] = `Bearer ${token}`;
        if (coords) headers['X-Location'] = btoa(`${coords}`);
        //headers['Client-Type'] = 'AP';

        request = request.clone({
            setHeaders: headers
        });

        return next.handle(request).pipe(
          catchError(error => {
            if (error.status == 401 && error.error.message == 'Invalid token.') {
              this.auth.logout();
              this.navCtrl.navigateRoot('/login');
            }
            return throwError(error);
          })
        );
    }
}
