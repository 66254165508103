import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  constructor(private router: Router, private modalController: ModalController) { }

  ngOnInit() {}

  redirectToRegisterPage(){
    this.router.navigate(['/register']);
    this.modalController.dismiss();
  }

  closeModal(){
    this.modalController.dismiss();
  }

}
