import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { AgmCoreModule } from '@agm/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TokenInterceptor } from './auth/interceptors/token.interceptor';
import { MarkdownModule } from 'ngx-markdown';

import it from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ComponentsModule } from './components/components.module';
import { SortPipe } from './pipes/sort.pipe';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
registerLocaleData(it);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAkhR9jyg1r2jVUmihQ1lu1dcJV08D_pW0'
    }),
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    HttpClientModule,
    FlatpickrModule.forRoot({ disableMobile: true }),
    PhotoGalleryModule,
    NgxGoogleAnalyticsModule.forRoot('G-H2BRC7VB0Q'),
    NgxGoogleAnalyticsRouterModule,
    ComponentsModule,
    NgxStripeModule.forRoot(environment.stripe_key),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor() { }

}

