import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  endpoint: { base: string; };

  constructor(private http: HttpClient) {
    this.endpoint = {
      base: `${environment.baseUrl}`
    };
  }

  createPaymentIntent(amount: number, token: string, type: 'manual' | 'automatic') {
    return this.http.post<{ client_secret: string; }>(`${this.endpoint.base}/stripe/pi`, {
      amount,
      payment_method: token,
      type
    }).toPromise();
  }
}
