import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service } from 'src/app/models/service';
import { ServiceTypesService } from 'src/app/services/service-types.service';

@Component({
  selector: 'service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss'],
})
export class ServiceFormComponent implements OnInit {

  @Input('service') service: Service;
  @Input('options') options: any = {};
  @Output('showProgram') showProgram: EventEmitter<Service> = new EventEmitter<Service>();
  @Output('remove') remove: EventEmitter<{ service: Service, options: any }> = new EventEmitter<{ service: Service, options: any }>();

  public types: any[] = [];

  constructor(private serviceTypesService: ServiceTypesService) { }

  async ngOnInit() {
    this.types = await this.serviceTypesService.find({}, null);
    this.service.type = this.service.type?.id;
  }

  deleteService() {
    this.remove.emit({ service: this.service, options: this.options });
  }

}
