import { Component, Input, OnInit } from '@angular/core';
import { Settings } from 'src/app/models/settings';
import { Global } from 'src/app/services/global';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {

  @Input("show") show: boolean = true;
  public password: string;
  public settings: Settings = Global.settings;

  constructor(private settingsService: SettingsService) { }

  ngOnInit() { }

  async enter() {
    const checkPassword = await this.settingsService.checkMaintenancePassword(this.password);
    this.show = !checkPassword;
  }

}
