import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-send-request',
  templateUrl: './send-request.component.html',
  styleUrls: ['./send-request.component.scss'],
})
export class SendRequestComponent implements OnInit {

  constructor(private router: Router, private modalController: ModalController) { }

  ngOnInit() {}

  goToReservationList(){
    this.modalController.dismiss();
  }

}

