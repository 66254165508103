import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.scss'],
})
export class PhotogalleryComponent implements OnInit {

  @Input('photos') photos: { originUrl: string, thumbnailUrl: string }[] = [];

  constructor() { }

  ngOnInit() { }

}
