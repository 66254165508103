import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Reservation } from '../models/reservation';
import { Service } from '../models/service';
import { ServiceConfiguration } from '../models/serviceConfiguration';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceConfigurationService extends CrudService<ServiceConfiguration> {

  constructor(protected http: HttpClient) {
    super(http);
    this.endpoint = {
      base: `${environment.baseUrl}/service-configurations`
    }
  }
}
