import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DashboardGuard } from './guards/dashboard.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [DashboardGuard],
    children: [
      {
        path: 'estate/:id',
        loadChildren: () => import('./pages/estate-form/estate-form.module').then( m => m.EstateFormPageModule)
      },
      {
        path: 'estate',
        loadChildren: () => import('./pages/estate-form/estate-form.module').then( m => m.EstateFormPageModule)
      },
      {
        path: 'estates/:id',
        loadChildren: () => import('./pages/estate-form/estate-form.module').then( m => m.EstateFormPageModule)
      },
      {
        path: 'estates',
        loadChildren: () => import('./pages/estates/estates.module').then( m => m.EstatesPageModule)
      },
      {
        path: 'reservations',
        loadChildren: () => import('./pages/reservations/reservations.module').then( m => m.ReservationsPageModule)
      },
    ]
  },
  {
    path: 'search-results/:selectedPlace/:date/:visitors',
    loadChildren: () => import('./pages/search-results/search-results.module').then( m => m.SearchResultsPageModule)
  },
  {
    path: 'estate-detail/:estateId/:visitors/:date',
    loadChildren: () => import('./pages/estate-detail/estate-detail.module').then( m => m.EstateDetailPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./pages/administration/administration.module').then( m => m.AdministrationPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'handle-reservation/:id',
    loadChildren: () => import('./pages/handle-reservation/handle-reservation.module').then( m => m.HandleReservationPageModule)
  },
  {
    path: 'reservations/:id',
    loadChildren: () => import('./pages/detail-reservation/detail-reservation.module').then( m => m.DetailReservationPageModule)
  },
  {
    path: 'p/:slug',
    loadChildren: () => import('./pages/dynamic-page/dynamic-page.module').then( m => m.DynamicPagePageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
