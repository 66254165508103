export class Settings {
    public id: any;
    public isMaintenance: boolean;
    public maintenancePassword: string;
    public maintenanceText: string;
    public touristRegister: boolean;
    public ownerRegister: boolean;

    constructor() {
        this.isMaintenance = true;
        this.maintenancePassword = 'NO-PASSWORD_123';
        this.touristRegister = true;
        this.ownerRegister = true;
    }
}