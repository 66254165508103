import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';
import { Reservation } from 'src/app/models/reservation';
import { ReservationsService } from 'src/app/services/reservations.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-confirm-reservation',
  templateUrl: './confirm-reservation.component.html',
  styleUrls: ['./confirm-reservation.component.scss'],
})
export class ConfirmReservationComponent implements OnInit {

  @Input('reservation') reservation: Reservation;

  constructor(private reservationsService: ReservationsService, private usersService: UsersService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private auth: AuthService
  ) { }

  async ngOnInit() {
    if (!this.reservation.user?.id) {
      this.reservation.user = await this.usersService.findOne(this.reservation.user);
    }

  }

  getTurn(turn_id: number) {
    if (!turn_id) {
      const start = new Date(this.reservation.service_config.startDate);
      const end = new Date(this.reservation.service_config.endDate);
      return { label: `${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')} - ${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}` };
    }
    return this.reservation.service_config?.turns?.find(r => r.id == turn_id);
  }

  async changeStatus(status: 'pending' | 'accepted' | 'rejected') {
    const reservation = await this.reservationsService.update({ status, id: this.reservation.id });
    this.reservation.status = reservation.status;
  }

  async deleteReservation() {

    const now = new Date();
    const ms = (new Date(this.reservation.reservationDate)).getTime();

    if (ms + 24 * 60 * 60 * 1000 < now.getTime() && !this.auth.getIdentity()?.owner) {
      const alert = await this.alertCtrl.create({
        header: 'Attenzione!',
        message: 'Non è più possibile eliminare questa prenotazione! Ricorda che puoi eliminare la tua prenotazione entro 24 dalla conferma.',
        buttons: [
          { role: 'confirm', text: 'Ok' }
        ]
      });
      alert.present();
      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Cancella prenotazione',
      message: 'Proseguendo cancellerai permanentemente la tua prenotazione. Sei sicuro di voler procedere?',
      buttons: [
        { role: 'confirm', text: 'Ok' },
        { role: 'cancel', text: 'Annulla' },
      ]
    });
    alert.present();
    alert.onDidDismiss().then(async res => {
      if (res.role == 'confirm') {
        try {
          await this.reservationsService.delete(this.reservation.id);
          this.modalCtrl.dismiss();
        } catch (error) {
          const errorAlert = await this.alertCtrl.create({
            header: 'Errore di cancellazione',
            message: 'Non è stato possibile annullare la prenotazione',
            buttons: [
              { role: 'confirm', text: 'Ok' },
            ]
          });
          errorAlert.present();
        }
      }
    });
  }

  async confirmAlert(){
    const alert = await this.alertCtrl.create({
      header:'Conferma prenotazione',
      message: 'Sei sicuro di voler confermare la prenotazione?',
      buttons: [
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            this.changeStatus('accepted');
          }
        },{
          text: 'no',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
  }

  async refuseAlert(){
    const alert = await this.alertCtrl.create({
      header:'Rifiuta prenotazione',
      message: 'Sei sicuro di voler rifiutare la prenotazione?',
      buttons: [
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            this.changeStatus('rejected');
          }
        },{
          text: 'no',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
  }

  statusReservationCheck(){
    console.log(this.reservation);
    if(this.reservation.status === 'accepted'){
      document.getElementById('buttons').style.display = 'none';
      const p = document.createElement('p');
      document.getElementById('toolbar').appendChild(p);
      p.innerHTML = 'La prenotazione è stata accettata';
    }else if (this.reservation.status === 'rejected'){
      document.getElementById('buttons').style.display = 'none';
      const paragraph =  document.createElement('p');
      document.getElementById('toolbar').appendChild(paragraph);
      paragraph.innerHTML = 'La prenotazione è stata rifiutata';
    }
  }

}
