import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {

  constructor(private auth: AuthService, private navCtrl: NavController, private popoverCtrl: PopoverController) { }

  ngOnInit() { }

  hasRole(type: 'owner' | 'authenticated') {
    return this.auth.hasRoles([type]);
  }

  get owner() {
    return this.auth.owner;
  }

  get isAdmin() {
    return this.auth.hasRoles(['admin']);
  }
  goto(url: string) {
    this.navCtrl.navigateForward(url);
    this.popoverCtrl.dismiss();
  }

  logout() {
    this.auth.logout();
    this.popoverCtrl.dismiss();
    this.navCtrl.navigateRoot('/');
  }

}
