import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Estate } from '../models/estate.model';
import { CrudService } from "./crud.service";
import { environment } from './../../environments/environment';
import { ServicesService } from './services.service';
import { ServiceConfigurationService } from './service-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EstatesService extends CrudService<Estate> {

  constructor(protected http: HttpClient, private servicesService: ServicesService, private serviceConfigurationService: ServiceConfigurationService) {
    super(http);
    this.endpoint = {
      base: `${environment.baseUrl}/estates`
    }
  }

  async search(bounds: any, where: any) {
    return this.http.post<Estate[]>(`${environment.baseUrl}/estates/search`, { bounds }).toPromise();
  }


  async add(estate: Estate) {
    const formData = new FormData();
    formData.append('files.cover', estate.cover);
    for (let img of estate.imgs) {
      formData.append('files.imgs', img);
    }
    const clone: Estate = JSON.parse(JSON.stringify(estate));
    delete clone.cover;
    delete clone.imgs;
    formData.append('data', JSON.stringify(clone));

    const savedEstate = await this.http.post<Estate>(this.endpoint.base, formData).toPromise();
    // for (let service of estate.services) {
    //   service.estate = savedEstate.id;
    //   const s = await this.servicesService.add(service);
    //   for (let config of service.config) {
    //     config.service = s.id;
    //     this.serviceConfigurationService.add(config);
    //   }
    // }

    return savedEstate;
  }

  async update(estate: Estate) {
    const formData = new FormData();
    if (estate.cover instanceof File) {
      formData.append('files.cover', estate.cover);
    }
    for (let img of estate.imgs) {
      formData.append('files.imgs', img);
    }
    const clone: Estate = JSON.parse(JSON.stringify(estate));
    delete clone.cover;
    delete clone.imgs;
    delete clone.services;
    formData.append('data', JSON.stringify(clone));

    const savedEstate = await this.http.put<Estate>(`${this.endpoint.base}/${estate.id}`, formData).toPromise();
    for (let service of estate.services) {
      service.estate = savedEstate.id;
      const configs = service.config;
      delete service.config;
      let s = null;
      if (service.id) {
        s = await this.servicesService.update(service);
      } else {
        s = await this.servicesService.add(service);
      }

      for (let config of configs) {
        config.service = s.id;
        if (config.id) {
          s = await this.serviceConfigurationService.update(config);
        } else {
          s = await this.serviceConfigurationService.add(config);
        }
      }
    }

    return savedEstate;
  }

  async getStatistics() {
    return await this.http.get<{ total: number, provinces: { [key: string]: number } }>(`${this.endpoint.base}/stats`).toPromise();
  }
}
