import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Service } from '../models/service';
import { ServiceConfiguration } from '../models/serviceConfiguration';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService extends CrudService<Service> {

  constructor(protected http: HttpClient) {
    super(http);
    this.endpoint = {
      base: `${environment.baseUrl}/services`
    }
  }

  nextEvents(fk_estate?: number) {
    return this.http.get<ServiceConfiguration[]>(`${this.endpoint.base}/next-events?${fk_estate ? 'estate=' + fk_estate : ''}`).toPromise();
  }
}
