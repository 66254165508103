import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { ModalController } from '@ionic/angular';
import { PaymentsService } from 'src/app/services/payments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  @Input() captureMethod: "manual" | "automatic" = 'automatic';
  @Input() price: number = 0;
  @Input() title: string = 'Pagamento';
  @Output() tokenCreated: EventEmitter<string> = new EventEmitter();

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontWeight: '300',
        fontFamily: '"Roboto',
        fontSize: '20px',
      }
    }
  };

  elementsOptions: StripeElementsOptions;

  stripeTest: FormGroup;
  termsAndConditions: string;

  constructor(private fb: FormBuilder, private stripeService: StripeService, private modalController: ModalController,
    private paymentsService: PaymentsService, private translate: TranslateService) {
    this.translate.get('termsAndConditions').subscribe(res => {
      this.termsAndConditions = res;
    });
  }


  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      cap: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]]
    });
    this.elementsOptions = {
      locale: 'it'
    };
  }
  async createToken() {
    const name = `${this.stripeTest.get('name').value || ''} ${this.stripeTest.get('surname').value || ''}`;
    const pmResult = await this.stripeService.createPaymentMethod({
      card: this.card.element,
      type: 'card'
    }).toPromise();
    const pi = await this.paymentsService.createPaymentIntent(this.price * 100, pmResult.paymentMethod.id, this.captureMethod);
    this.modalController.dismiss(pi);
  }
}
