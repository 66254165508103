import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ConstructionPeriodsService  extends CrudService<{ id: any, label: string, value: string }> {

  constructor(protected http: HttpClient) {
    super(http);
    this.endpoint = {
      base: `${environment.baseUrl}/construction-periods`
    }
  }
}
