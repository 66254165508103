import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  storeLocalStorage<T>(value: T, key: string) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    }
    else {
      localStorage.setItem(key, (value as any));
    }
  }

  getLocalStorage<T>(key): T {
    return localStorage.getItem(key) as any;
  }

}
