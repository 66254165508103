import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitors-counter',
  templateUrl: './visitors-counter.component.html',
  styleUrls: ['./visitors-counter.component.scss'],
})
export class VisitorsCounterComponent implements OnInit {

  n: number = 1;
  searchInputs: any;

  constructor() { }

  ngOnInit() {
    this.n = this.searchInputs.visitors || 1;
  }

  addQta(n: number) {
    if (this.n + n < 1) {
      this.n = 1;
      this.searchInputs.visitors = 1;
      return;
    }

    this.n += n;
    this.searchInputs.visitors = this.n;

  }



}
