import { Reservation } from "./reservation";
import { Service } from "./service";

export interface ITurn { label: string, start: Date, end: Date, id?: number };

export class ServiceConfiguration {
    id: string;
    price: number;
    startDate: Date;
    endDate: Date;
    maxGuest: number;
    service: Service;
    type: string;
    week: string[] = [];
    maxBookingDate: string | Date;
    periodType: string;
    note: string;
    n_pax_admitted: number;
    turn_period: number;
    turn_preparation: number;
    monthly_date: number;
    turns: ITurn[];
    reservation: Reservation[];
    n_reservations: number;
    nextDate: Date;

    constructor(config: Partial<ServiceConfiguration> = {}) {
        this.id = config.id;
        this.price = config.price;
        this.startDate = config.startDate || new Date();
        this.endDate = config.endDate || new Date();
        this.maxGuest = config.maxGuest;
        this.type = config.type;
        this.week = config.week || [];
        this.note = config.note;
        this.n_pax_admitted = config.n_pax_admitted;
        this.maxBookingDate = config.maxBookingDate;
        this.turn_period = config.turn_period;
        this.turn_preparation = config.turn_preparation;
        this.turns = config.turns;
        this.reservation = config.reservation;
        this.service = config.service;
    }
}
