// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:1337'
  // baseUrl: 'https://hidden-gems-server.herokuapp.com',
  // baseUrl: 'https://hidden-gems-server.herokuapp.com'
  baseUrl: 'https://api.meravillas.com',
  // stripe_key: 'pk_test_51MjoVEEZL6IOBOHeEZzgsuRlIi5yJhRQTWTP7JTPmRJAdwPNPeUCSRxPzWQnqKEgMuhNEG4Y26DPLOnDcj75rWPv00QacfqSQW'
  stripe_key: 'pk_live_51MjoVEEZL6IOBOHe22uI7E40MK53Sjs8NCdjLX9ZmLt9nyn2oxWKEvUSo2gv5jCfOAEiTBLcU5HYqs0hLtbNc9Fh00fOUaKcyD'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
