export async function showCookiePolicy(cookieUrl, message, acceptButton, linkLabel) {
    if (document.querySelector('*[aria-label="cookieconsent"]')) document.body.removeChild(document.querySelector('*[aria-label="cookieconsent"]'));
    let cc = window as any;
    cc.cookieconsent.initialise({
        palette: {
            popup: {
                background: "black"
            },
            button: {
                background: "var(--ion-color-tertiary)",
                text: "white"
            }
        },
        theme: "classic",
        content: {
            message: message,
            dismiss: acceptButton,
            allow: 'Accetta',
            deny: 'Rifiuta',
            link: linkLabel,
            href: "/p/" + cookieUrl
        }
    });
}